/* eslint-disable array-callback-return */
'use strict';
var debounce = require('lodash/debounce');

/**
 * Get display information related to screen size
 * @param {jQuery} element - the current carousel that is being used
 * @returns {Object} an object with display information
 */

/* getting the categories-carousel-collapse */
let carouselMobile = document.getElementsByClassName('categories-carousel-collapse');

/* starts with hidden carousel */
if ($(carouselMobile[carouselMobile.length - 1]).hasClass('categories-carousel-collapse')) {
    carouselMobile[carouselMobile.length - 1].style.display = 'none';
}

/* Collapse Container */
let divCollapse = document.createElement('div');
divCollapse.classList.add('divCollapse');
/* Collapse Button */
let btn = document.createElement('div');
btn.classList.add('btnCollapse');
btn.innerHTML = "<i class='fa fa-angle-up' aria-hidden='true'></i>";

/* To display the collapse you must write a text in the 'Carousel Title' section but it will not be shown because of the null value */
let carouselTitle = document.getElementsByClassName('carousel-title');
if ($(carouselMobile[carouselMobile.length - 1]).hasClass('categories-carousel-collapse')) {
    carouselTitle[carouselTitle.length - 1].textContent = null;
    carouselTitle[carouselTitle.length - 1].style.textAlign = 'center';
}

/* append */
if ($(carouselMobile[carouselMobile.length - 1]).hasClass('categories-carousel-collapse')) {
    carouselTitle[carouselTitle.length - 1].appendChild(divCollapse);
    divCollapse.appendChild(btn);
}


/* Collapse Event */
btn.addEventListener('click', () => {
    $('.categories-carousel-collapse').on('hidden.bs.collapse', function () {
        carouselMobile[carouselMobile.length - 1].style.display = 'none';
        btn.innerHTML = "<i class='fa fa-angle-up' aria-hidden='true'></i>";
        wppIcon[0].style.display = 'block';
        let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
        wcxChat[0].style.display = 'block';
    });
    $('.categories-carousel-collapse').on('shown.bs.collapse', function () {
        carouselMobile[carouselMobile.length - 1].style.display = 'block';
        btn.innerHTML = "<i class='fa fa-angle-down' aria-hidden='true'></i>";
        wppIcon[0].style.display = 'none';
        let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
        wcxChat[0].style.display = 'none';
    });
    $('.categories-carousel-collapse').collapse('toggle');
});


let experienceComponent = document.getElementsByClassName('experience-commerce_layouts-carousel');
let experienceComponentRow = experienceComponent[experienceComponent.length - 1].querySelector('.row');
let mainContent = document.getElementById('maincontent');
let col12 = document.getElementsByClassName('col-12');
let categCarouselItem = document.getElementsByClassName('carousel-item');

/* position:fixed; the whole component + mainContent padding fix*/
if ($(carouselMobile[carouselMobile.length - 1]).hasClass('categories-carousel-collapse')) {
    experienceComponent[experienceComponent.length - 1].style.position = 'sticky';
    experienceComponent[experienceComponent.length - 1].style.bottom = 0;
    experienceComponent[experienceComponent.length - 1].style.width = '100%';
    experienceComponent[experienceComponent.length - 1].style.zIndex = '2';
    mainContent.style.padding = '0px';
    col12[col12.length - 2].style.padding = '0px';
    experienceComponentRow.classList.add('mx-0');
}

function screenSize(element) {
    var result = {
        itemsToDisplay: null,
        sufficientSlides: true
    };
    var viewSize = $(window).width();
    var extraSmallDisplay = element.data('xs');
    var smallDisplay = element.data('sm');
    var mediumDisplay = element.data('md');
    var numberOfSlides = element.data('number-of-slides');

    if (viewSize <= 575.98) {
        result.itemsToDisplay = extraSmallDisplay;
    } else if (viewSize >= 576 && viewSize <= 768.98) {
        result.itemsToDisplay = smallDisplay;
    } else if (viewSize >= 769) {
        result.itemsToDisplay = mediumDisplay;
    }
    if (result.itemsToDisplay && numberOfSlides <= result.itemsToDisplay) {
        result.sufficientSlides = false;
    }

    return result;
}

/**
 * Makes the next element to be displayed next unreachable for screen readers and keyboard nav
 * @param {jQuery} element - the current carousel that is being used
 */
function hiddenSlides(element) {
    var carousel;

    if (element) {
        carousel = element;
    } else {
        carousel = $(
            '.experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel, #carouselRecommendedProducts'
        );
    }

    var screenSizeInfo = screenSize(carousel);

    var lastDisplayedElement;
    var elementToBeDisplayed;

    switch (screenSizeInfo.itemsToDisplay) {
        case 2:
            lastDisplayedElement = carousel.find(
                '.active.carousel-item + .carousel-item'
            );
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item'
            );
            break;
        case 3:
            lastDisplayedElement = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item'
            );
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            break;
        case 4:
            lastDisplayedElement = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            break;
        case 6:
            lastDisplayedElement = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            break;
        default:
            break;
    }

    carousel
        .find('.active.carousel-item')
        .removeAttr('tabindex')
        .removeAttr('aria-hidden');
    carousel
        .find('.active.carousel-item')
        .find('a, button, details, input, textarea, select')
        .removeAttr('tabindex')
        .removeAttr('aria-hidden');

    if (lastDisplayedElement) {
        lastDisplayedElement.removeAttr('tabindex').removeAttr('aria-hidden');
        lastDisplayedElement
            .find('a, button, details, input, textarea, select')
            .removeAttr('tabindex')
            .removeAttr('aria-hidden');
    }

    if (elementToBeDisplayed) {
        elementToBeDisplayed.attr('tabindex', -1).attr('aria-hidden', true);
        elementToBeDisplayed
            .find('a, button, details, input, textarea, select')
            .attr('tabindex', -1)
            .attr('aria-hidden', true);
    }
}

function carouselInit(observer) {
    $('body').on('carousel:setup', function () {
        hiddenSlides();
    });
    var carousel = $(
        '.container .main_lottery, .experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel, #carouselRecommendedProducts'
    );


    if (carousel.length > 0) {
        carousel.map((key) => {
            var dataset = carousel[key].dataset;
            var current = $('.' + dataset.identifier);
            var interval = current[0].dataset.interval;
            var viewSize = $(window).width();
            var controlsXs = current[0].dataset.controlsXs;
            var controlsSm = current[0].dataset.controlsSm;
            var controlsMd = current[0].dataset.controlsMd;
            var displayControls;
            if (viewSize <= 575.98) {
                displayControls = controlsXs;
            } else if (viewSize >= 576 && viewSize <= 768.98) {
                displayControls = controlsSm;
            } else if (viewSize >= 769) {
                displayControls = controlsMd;
            }

            if (displayControls === 'false') {
                current.find('.carousel-control-prev').hide();
                current.find('.carousel-control-next').hide();
            }
            if (
                current[0].querySelectorAll('.carousel-item').length <=
                dataset.md
            ) {
                current.carousel('pause');
            } else {
                current.carousel('cycle');
                current.carousel({ interval: interval });
            }
        });
    }
    carousel.on('touchstart', function (touchStartEvent) {
        var screenSizeInfo = screenSize($(this));

        if (screenSizeInfo.sufficientSlides) {
            var xClick = touchStartEvent.originalEvent.touches[0].pageX;
            $(this).one('touchmove', function (touchMoveEvent) {
                var xMove = touchMoveEvent.originalEvent.touches[0].pageX;
                if (Math.floor(xClick - xMove) > 5) {
                    $(this).carousel('next');
                } else if (Math.floor(xClick - xMove) < -5) {
                    $(this).carousel('prev');
                }
            });
            carousel.on('touchend', function () {
                $(this).off('touchmove');
            });
        }
    });

    carousel.on('slide.bs.carousel', function (e) {
        var activeCarouselPosition = $(e.relatedTarget).data('position');
        $(this).find('.pd-carousel-indicators .active').removeClass('active');
        $(this)
            .find(
                ".pd-carousel-indicators [data-slide-to='" +
                activeCarouselPosition +
                "']"
            )
            .addClass('active');

        var extraSmallDisplay = $(this).data('xs');
        var smallDisplay = $(this).data('sm');
        var mediumDisplay = $(this).data('md');

        var arrayOfSlidesToDisplay = [];

        if (!$(this).hasClass('insufficient-xs-slides')) {
            arrayOfSlidesToDisplay.push(extraSmallDisplay);
        }

        if (!$(this).hasClass('insufficient-sm-slides')) {
            arrayOfSlidesToDisplay.push(smallDisplay);
        }

        if (!$(this).hasClass('insufficient-md-slides')) {
            arrayOfSlidesToDisplay.push(mediumDisplay);
        }

        var itemsToDisplay = Math.max.apply(Math, arrayOfSlidesToDisplay);

        var elementIndex = $(e.relatedTarget).index();
        var numberOfSlides = $('.carousel-item', this).length;
        var carouselInner = $(this).find('.carousel-inner');
        var carouselItem;

        if (elementIndex >= numberOfSlides - (itemsToDisplay - 1)) {
            var it = itemsToDisplay - (numberOfSlides - elementIndex);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction === 'left') {
                    carouselItem = $('.carousel-item', this).eq(i);

                    $(carouselItem).appendTo($(carouselInner));
                } else {
                    carouselItem = $('.carousel-item', this).eq(0);

                    $(carouselItem).appendTo($(carouselInner));
                }
            }
        }
    });

    carousel.on('slide.bs.carousel', function () {
        hiddenSlides($(this));
    });

    // Later, you can stop observing
    if (observer) observer.disconnect();
}

$(document).ready(function () {
    hiddenSlides();

    $(window).on(
        'resize',
        debounce(function () {
            hiddenSlides();
        }, 500)
    );

    // The node to be monitored
    var target = $('#maincontent')[0];

    // Create an observer instance
    var observer = new MutationObserver(function (mutations, obs) {
        mutations.forEach(function (mutation) {
            var newNodes = mutation.addedNodes; // DOM NodeList
            if (newNodes !== null) { // If there are new nodes added
                var $nodes = $(newNodes); // jQuery set
                $nodes.map(key => {
                    if ($($nodes[key]).hasClass('product-recommendation-listing')) {
                        carouselInit(obs);
                    }
                });
            }
        });
    });

    // Configuration of the observer:
    var config = {
        attributes: true,
        childList: true,
        subtree: true
    };

    // Pass in the target node, as well as the observer options
    observer.observe(target, config);

    carouselInit();
});

/* wpp icon & wcx chat style modifications */
let wppIcon = document.getElementsByClassName('whatsapp_icon');
if (wppIcon[0]) {
    window.onload = function () {
        let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
    };
    let mediumBp = matchMedia('(min-width: 543px)');
    let changeSize = mql => {
        if (mql.matches) {
            wppIcon[0].style.bottom = '7px';
            window.onload = function () {
                let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
                wcxChat[0].style.bottom = '0px';
            };
        } else if ($(carouselMobile[carouselMobile.length - 1]).hasClass('categories-carousel-collapse')) {
            wppIcon[0].style.bottom = '20px';
            window.onload = function () {
                let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
                wcxChat[0].style.bottom = '10px';
            };
        } else {
            wppIcon[0].style.bottom = '7px';
            window.onload = function () {
                let wcxChat = document.getElementsByClassName('styles_button_main__2rvIi');
                wcxChat[0].style.bottom = '0px';
            };
        }
    };

    mediumBp.addListener(changeSize);
    changeSize(mediumBp);
}
